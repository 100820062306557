.Home {
  font-size: larger;
}

.Bio {
  margin: 0 auto;
  max-width: 45em;
  padding-left: 1em;
  padding-right: 1em;
  text-align: start;
}

a {
  color: orange;
}

a:link {
  color: orange;
}

a:visited {
  color: green;
}

a:hover {
  color: purple;
}
