@font-face {
  font-family: manrope;
  src: url(./fonts/manrope/light.woff);
}
.App {
  text-align: center;
  font-family: "manrope";
  height: 20vh;
}

.header {
  height: 4em;
}

.content {
  height: calc(100vh - 4em);
  overflow-y: auto;
}
