.Projects {
  font-size: large;
  color: inherit;
}

.project {
  background-color: rgba(60, 0, 160, 0.25);
  margin: 1em auto;
  max-width: 45em;
  padding: 0.01em 1em;
  transition: box-shadow 0.3s ease;
}

.project:hover {
  cursor: pointer;
  box-shadow: 0px 0px 10px 5px rgba(250, 100, 100, 0.9);
  transition: box-shadow 0.3s ease;
}

.Projects hr {
  max-width: 90vw;
  margin: 0 auto;
}

.Projects a:visited {
  color: inherit;
}
.Projects a:hover {
  color: inherit;
}
.Projects a:link {
  color: inherit;
}

.Projects a { 
  text-decoration: none;
  color: inherit;
  outline: none;
}

.Projects img {
  width: 100%;
}
