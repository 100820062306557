.p5Canvas {
  width: 40% !important;
  height: auto !important;
}

.SimSettings {
  display: grid;
  grid-template-columns: 50% 50%;
}

