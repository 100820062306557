.navbar-item {
  height: 100%;
  width: 7em;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.0);
  transition: background-color 1s;
  float: inherit;
}

.navbar-item:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  transition: background-color 1s;
}

.navbar-item-content {
  color: white; 
  font-size: larger;
}

