.navbar {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: grid;
  position: relative;
  z-index: 10;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.5);
  grid-template-columns: 45vw 10vw 45vw;
}

.left-side {
  grid-column: 1;
  float: left;
  margin-right: auto;
  display: flex;
}

.logo-container {
  grid-column: 2;
  height: 3.5em;
  margin-top: 0.3em;
  cursor: pointer;
  animation: spin 3s linear infinite;
  animation-play-state: paused;
}

.right-side {
  grid-column: 3;
  float: right;
  display: flex;
  justify-content: flex-end;
}

.theme-switcher {
  height: 100%;
  font-size: x-large;
  margin: 0 0.2em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-container:hover {
  animation-play-state: running;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Opera and Firefox */
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar a:visited {
  color: inherit;
}
.navbar a:hover {
  color: inherit;
}
.navbar a:link {
  color: inherit;
}

.navbar a { 
  text-decoration: none;
  color: inherit;
  outline: none;
}
